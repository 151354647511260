:root {
  --app-height: 100vh;
  --primary-color: #ff9500;
}

body {
  margin: 0;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

a {
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

main {
  padding: 0 16px;
  margin: 0 auto;
  max-width: 432px;
}

html,
* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  max-width: 500px;
  margin: 0 auto;
  color: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  height: var(--app-height);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: white;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: white;
  margin-bottom: 24px;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #272729;
}

h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
}

p {
  margin: 24px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(255, 255, 255, 0.8);
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: var(--primary-color);
}

ul {
  list-style: -moz-ethiopic-numeric;
  margin: 0;
  padding: 0 0 0 16px;
}

li {
  margin: 5px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #ffffff;
}

li span,
p span {
  color: var(--primary-color);
}

li a,
p a {
  color: var(--primary-color);
  text-decoration: underline;
}
